var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Space } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
export var Hero = function (props) { return (_jsx("div", __assign({ style: {
        color: '#FFF',
        backgroundImage: 'linear-gradient( 160deg, #00d0bf 10%, #003666 100%)',
        borderRadius: '0 0 85% 85% / 30%',
        height: '180px',
        width: '100%',
        alignContent: 'center',
        marginBottom: '20px',
    } }, { children: _jsxs(Space, __assign({ direction: "vertical", align: "center", style: { width: '100%' } }, { children: [_jsx(Title, __assign({ level: 1, style: { paddingTop: '30px', color: 'white' } }, { children: props.title })), _jsx(Paragraph, __assign({ ellipsis: { rows: 2 }, style: { color: 'white' } }, { children: props.subTitle }))] })) }))); };
