var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { UploadOutlined } from '@ant-design/icons';
import { Dropdown, Form as AntdForm, Input, Menu, Spin, Upload } from 'antd';
import { useState } from 'react';
import { Button } from './Button';
import { Hero } from './Hero';
export var titles = [
    { key: 2, label: 'Dr', title: 'Dr' },
    { key: 3, label: 'Miss', title: 'Miss' },
    { key: 4, label: 'Mr', title: 'Mr' },
    { key: 5, label: 'Mrs', title: 'Mrs' },
    { key: 6, label: 'Ms', title: 'Ms' },
    { key: 7, label: 'Professor', title: 'Professor' },
    { key: 12, label: 'Sir', title: 'Sir' },
    { key: 14, label: 'Baron', title: 'Baron' },
];
export var Form = function (props) {
    var _a;
    var _b = useState('Mr'), title = _b[0], setTitle = _b[1];
    var form = AntdForm.useForm()[0];
    var _c = useState(null), file = _c[0], setFile = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    function titleChange(item) {
        var selected = titles.filter(function (x) { return x.key === Number(item.key); })[0];
        setTitle(selected.title);
    }
    var onFinishFailed = function (errorInfo) {
        setLoading(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Hero, { title: "Applying for job: ".concat(props.jobTitle) }), _jsxs(AntdForm, __assign({ style: { margin: '0 20px' }, form: form, onFinish: function (values) {
                    var _a;
                    setLoading(true);
                    props.onFinish({
                        forename: values.forename,
                        surname: values.surname,
                        email: values.email,
                        cvFile: file,
                        cvName: (_a = values.cv.originFileObj) === null || _a === void 0 ? void 0 : _a.name,
                        candidateTitle: title,
                    });
                }, onFinishFailed: onFinishFailed, layout: "vertical" }, { children: [_jsx(AntdForm.Item, __assign({ label: "Title", name: "title" }, { children: _jsx(Dropdown, __assign({ overlay: _jsx(Menu, { onClick: titleChange, items: titles }), placement: "bottom", arrow: true }, { children: _jsx(Button, __assign({ style: { minWidth: '150px' } }, { children: (_a = titles.find(function (x) { return x.label === title; })) === null || _a === void 0 ? void 0 : _a.label })) })) })), _jsx(AntdForm.Item, __assign({ label: "Forename", name: "forename", rules: [{ required: true, message: 'Please input your forename' }] }, { children: _jsx(Input, {}) })), _jsx(AntdForm.Item, __assign({ label: "Surname", name: "surname", rules: [{ required: true, message: 'Please input your surname' }] }, { children: _jsx(Input, {}) })), _jsx(AntdForm.Item, __assign({ label: "Email", name: "email", rules: [{ required: true, message: 'Please input your email' }] }, { children: _jsx(Input, {}) })), _jsx(AntdForm.Item, __assign({ label: "Upload your CV", name: "cv", valuePropName: "file", getValueFromEvent: function (_a) {
                            var file = _a.file;
                            return file;
                        }, extra: 'Files should be .doc, .docx or .pdf', rules: [{ required: true, message: 'Please attach your CV' }] }, { children: _jsx(Upload, __assign({ multiple: false, maxCount: 1, accept: '.doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document', customRequest: function (_a) {
                                var file = _a.file, onSuccess = _a.onSuccess;
                                var reader = new FileReader();
                                reader.onloadend = function () {
                                    setFile(reader.result);
                                };
                                if (file && file instanceof Blob) {
                                    reader.readAsDataURL(file);
                                }
                                setTimeout(function () {
                                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess('ok');
                                }, 0);
                            } }, { children: _jsx(Button, __assign({ icon: _jsx(UploadOutlined, {}) }, { children: "Click to Upload" })) })) })), _jsx(AntdForm.Item, __assign({ wrapperCol: { span: 4, offset: 10 } }, { children: loading ? _jsx(Spin, {}) :
                            _jsx(Button, __assign({ type: "primary", htmlType: "submit", size: "large" }, { children: "Send your application" })) }))] }))] }));
};
