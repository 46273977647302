var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useApiSdkContext } from './provider';
export function useJobs(filter) {
    var _this = this;
    var token = useApiSdkContext();
    return useQuery(['jobs'], function () { return __awaiter(_this, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!token.accessToken) return [3 /*break*/, 2];
                return [4 /*yield*/, token.apiClient.jobs_GetAll()];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = [];
                _b.label = 3;
            case 3: return [2 /*return*/, (_a)];
        }
    }); }); }, {
        select: function (jobs) {
            if (!filter) {
                return jobs;
            }
            var returnData = jobs;
            if (filter.title) {
                returnData = returnData.filter(function (job) {
                    return job.title.toLowerCase().includes(filter.title.toLowerCase());
                });
            }
            if (filter.status) {
                returnData = returnData.filter(function (job) { return job.status === filter.status; });
            }
            return returnData;
        },
    });
}
export function useJob(id) {
    var _this = this;
    var token = useApiSdkContext();
    return useQuery(['jobs', id], function () { return __awaiter(_this, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!token.accessToken) return [3 /*break*/, 2];
                return [4 /*yield*/, token.apiClient.jobs_Get(id)];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = undefined;
                _b.label = 3;
            case 3: return [2 /*return*/, _a];
        }
    }); }); });
}
export function useCandidates() {
    var _this = this;
    var token = useApiSdkContext();
    return useQuery(['candidates'], function () { return __awaiter(_this, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!token.accessToken) return [3 /*break*/, 2];
                return [4 /*yield*/, token.apiClient.candidate_GetAll()];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = undefined;
                _b.label = 3;
            case 3: return [2 /*return*/, _a];
        }
    }); }); });
}
export function useCandidate(emailAddress) {
    var _this = this;
    var token = useApiSdkContext();
    return useQuery(['candidates', emailAddress], function () { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!token.accessToken) return [3 /*break*/, 2];
                    return [4 /*yield*/, token.apiClient.candidate_Get(emailAddress)];
                case 1:
                    _a = _b.sent();
                    return [3 /*break*/, 3];
                case 2:
                    _a = undefined;
                    _b.label = 3;
                case 3: return [2 /*return*/, _a];
            }
        });
    }); });
}
export function useTimesheets(start, end) {
    var _this = this;
    var token = useApiSdkContext();
    return useQuery(['timesheets', start, end], function () { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!token.accessToken) return [3 /*break*/, 2];
                    return [4 /*yield*/, token.apiClient.timesheet_Get(start, end)];
                case 1:
                    _a = _b.sent();
                    return [3 /*break*/, 3];
                case 2:
                    _a = undefined;
                    _b.label = 3;
                case 3: return [2 /*return*/, _a];
            }
        });
    }); });
}
export function useTimesheetsCsv(start, end) {
    var _this = this;
    var token = useApiSdkContext();
    return useQuery(['timesheets', start, end], function () { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!token.accessToken) return [3 /*break*/, 2];
                    return [4 /*yield*/, token.apiClient.timesheet_GetCSV(start, end)];
                case 1:
                    _a = _b.sent();
                    return [3 /*break*/, 3];
                case 2:
                    _a = undefined;
                    _b.label = 3;
                case 3: return [2 /*return*/, _a];
            }
        });
    }); }, {
        enabled: start != null && end != null,
    });
}
export function useInterval(callback, delay) {
    var savedCallback = useRef(function () { return null; });
    // Remember the latest callback
    useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    // Setup the interval
    useEffect(function () {
        function tick() {
            savedCallback.current();
        }
        if (delay && delay > 0) {
            var id_1 = setInterval(tick, delay);
            return function () {
                clearInterval(id_1);
            };
        }
    }, [callback, delay]);
}
