import { InteractionStatus } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { MenuItemType } from 'antd/lib/menu/hooks/useItems'
import { Header } from 'pn.agencyportal.components/dist/src/components/Toolbox'
import { useNavigate } from 'react-router-dom'
import { loginRequest } from './Config/authConfig'
import { useAppAccount } from './Utils/useAppAccount'

export const MsalHeader = () => {
  const navigate = useNavigate()
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const account = useAppAccount()

  const items: MenuItemType[] = [
    { label: 'Jobs', key: 'jobs', onClick: () => navigate('/jobs') },
    {
      label: 'Candidates',
      key: 'candidates',
      onClick: () => navigate('/candidates'),
    },
    {
      label: 'Timesheets',
      key: 'timesheets',
      onClick: () => navigate('/timesheets'),
    },
  ]

  return (
    <Header
      items={items}
      handleLogin={() => instance.loginRedirect(loginRequest)}
      handleLogout={() => instance.logoutRedirect()}
      isAuthenticated={isAuthenticated}
      inProgress={
        inProgress !== InteractionStatus.Startup &&
        inProgress !== InteractionStatus.HandleRedirect
      }
      user={{ name: account.fullName }}
    />
  )
}
