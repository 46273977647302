import { AssignmentHeaderStatusEnum } from 'pn.agencyportal.api.sdk'
import { JobFilter } from 'pn.agencyportal.api.sdk/hooks'
import { useState } from 'react'

export function useJobFilters() {
  const [filter, setFilter] = useState<JobFilter>({
    title: undefined,
    status: 'Request',
  })

  function onStatusChange({ key }: { key: any }) {
    setFilter({
      title: filter.title,
      status: statuses[key].value,
    })
  }

  function onTitleChange(value: string) {
    setFilter({
      title: value,
      status: filter.status,
    })
  }

  return { filter, onStatusChange, onTitleChange }
}

export type JobStatus = {
  key: number
  label: string
  value: AssignmentHeaderStatusEnum | undefined
}

export const statuses: JobStatus[] = [
  {
    key: 0,
    label: 'Show All',
    value: undefined,
  },
  {
    key: 1,
    label: 'Cancelled',
    value: 'Cancelled',
  },
  {
    key: 2,
    label: 'Expired',
    value: 'Expired',
  },
  {
    key: 3,
    label: 'In Creation',
    value: 'InCreation',
  },
  {
    key: 4,
    label: 'Live',
    value: 'Live',
  },
  {
    key: 5,
    label: 'Request',
    value: 'Request',
  },
]
