import { useAccount } from '@azure/msal-react'

export function useAppAccount() {
  const account = useAccount()
  const claims = account?.idTokenClaims!

  const fullName = claims
    ? `${claims['given_name']} ${claims['family_name']}`
    : undefined

  const username = account?.username ?? ''
  const agencyId = claims ? (claims['extension_AgencyId'] as number) : undefined

  return { fullName, username, agencyId }
}
