import { Button, Dropdown, Input, Menu, Space } from 'antd'
import { useJobs } from 'pn.agencyportal.api.sdk/hooks'
import {
  Hero,
  JobCard,
} from 'pn.agencyportal.components/dist/src/components/Toolbox/'
import { useNavigate } from 'react-router-dom'
import { statuses, useJobFilters } from './JobsFilter'

const Jobs = () => {
  const filters = useJobFilters()
  const jobs = useJobs(filters.filter)
  const navigate = useNavigate()

  const { data } = jobs

  const filter = (
    <Space direction="horizontal" align="center" size="middle">
      <label htmlFor="title">Search</label>
      <Input
        name="title"
        placeholder="Search by job title"
        onChange={(e) => {
          filters.onTitleChange(e.target.value)
        }}
      />
      <label htmlFor="filter">Status</label>
      <Dropdown
        overlay={<Menu onClick={filters.onStatusChange} items={statuses} />}
        placement="bottom"
        arrow
      >
        <Button style={{ minWidth: '150px' }}>
          {statuses.find((x) => x.value === filters.filter?.status)?.label}
        </Button>
      </Dropdown>
    </Space>
  )

  const jobCards = (data ?? []).map((item) => {
    return (
      <JobCard
        key={item.assignmentHeaderId}
        jobTitle={item.title}
        jobDescription={item.description}
        datePosted={item.postedDate}
        viewDetailsOnClick={() => navigate(`/jobs/${item.assignmentHeaderId}`)}
        applyNowOnClick={
          item.status === 'Request'
            ? () => navigate(`/jobs/${item.assignmentHeaderId}/apply`)
            : undefined
        }
        jobState={item.status}
      />
    )
  })

  return (
    <div>
      <Hero
        title="Job Search"
        subTitle={`Total: ${(jobs && jobs.data?.length) ?? 0}`}
      />

      <Space
        direction="vertical"
        size="middle"
        align="center"
        style={{
          display: 'flex',
        }}
      >
        {filter}
        {jobCards.length > 0 ? jobCards : <>No Jobs found</>}
      </Space>
    </div>
  )
}

export default Jobs
