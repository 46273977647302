import { JobApplicationRequest } from 'pn.agencyportal.api.sdk/functions'
import { Button, Result } from 'antd'
import { useJob } from 'pn.agencyportal.api.sdk/hooks'
import { useApiSdkContext } from 'pn.agencyportal.api.sdk/provider'
import { Form } from 'pn.agencyportal.components/dist/src/components/Toolbox/'
import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { JobParams } from './JobParams'

export function JobApply() {
  const { jobId } = useParams<JobParams>()
  const { data } = useJob(Number.parseInt(jobId!))
  const token = useApiSdkContext()
  const [submitted, setSubmitted] = useState<Boolean>(false)
  const navigate = useNavigate()

  return !submitted ? (
    jobId ? (
      <Form
        jobTitle={data?.title ?? 'unknown'}
        onFinish={async (props) => {
          var promise = token.functionsClient?.jobApplicationHttpFunction_Post(
            new JobApplicationRequest({
              ...props,
              jobId: Number.parseInt(jobId),
              cvData:
                typeof props.cvFile === 'string' ? props.cvFile : undefined,
            })
          )
          promise?.then(() => setSubmitted(true))
          promise?.catch((err) => console.log(err))
          await promise
        }}
      />
    ) : (
      <>No Job ID provided</>
    )
  ) : (
    <Result
      status="success"
      title="Application submitted!"
      subTitle="Thank you for submitting this job application."
      extra={[
        <Button type="primary" key="more" onClick={() => navigate(`/jobs/`)}>Browse more jobs</Button>
        ]}
    />
  )
}
