import { Col, message, Row, Upload } from 'antd'
import Title from 'antd/lib/typography/Title'
import {
  CancelAssignmentRequest,
  CertificateOfComplianceRequest,
} from 'pn.agencyportal.api.sdk/functions'
import { useCandidate, useInterval } from 'pn.agencyportal.api.sdk/hooks'
import { useApiSdkContext } from 'pn.agencyportal.api.sdk/provider'
import {
  Button,
  CandidateJobCard,
  Hero,
} from 'pn.agencyportal.components/dist/src/components/Toolbox'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CandidateParams } from './CandidateParams'

export function Candidate() {
  const { emailAddress } = useParams<CandidateParams>()
  const candidateQuery = useCandidate(emailAddress!)
  const navigate = useNavigate()
  const token = useApiSdkContext()
  const candidate = candidateQuery.data
  const [uploaded, setUploaded] = useState(0)
  const [delay, setDelay] = useState<number | undefined>(0)

  useInterval(async () => {
    if (!candidate?.cocCompleted && uploaded === 1) candidateQuery.refetch()
    else {
      setDelay(0)
      setUploaded(2)
    }
  }, delay)

  function getFlag() {
    if (uploaded === 0 && !candidate?.cocCompleted) return '❌'
    if (uploaded === 1 && !candidate?.cocCompleted) return '⌛'
    if (uploaded === 2 || candidate?.cocCompleted) return '✅'
  }

  function cancelJob(item: CancelAssignmentRequest) {
    token.functionsClient
      ?.cancelAssignmentHttpFunction_Post(
        new CancelAssignmentRequest({
          assignmentHeaderId: item.assignmentHeaderId,
          emailAddress,
          jobAdvertId: item.jobAdvertId,
        })
      )
      .then(() => {
        candidateQuery.refetch()
      })
    message.success(`Job ${item.assignmentHeaderId} has now been cancelled`, 5)
  }

  return (
    <>
      <Hero title={`${candidate?.forename} ${candidate?.surname}`} />

      <Row>
        <Col flex="1 1 200px">
          <Title level={4}>Jobs Applied For</Title>

          <Row gutter={16}>
            {candidate?.jobs.map((item) => (
              <Col span={12}>
                <CandidateJobCard
                  key={item.assignmentHeaderId}
                  jobTitle={item.title}
                  jobDescription={item.description}
                  datePosted={item.postedDate}
                  jobState={item.candidateStatus}
                  viewDetailsOnClick={() =>
                    navigate(`/jobs/${item.assignmentHeaderId}`)
                  }
                  cancelOnClick={
                    item.candidateStatus !== ''
                      ? () => {
                          cancelJob(
                            new CancelAssignmentRequest({
                              assignmentHeaderId: item.assignmentHeaderId,
                              emailAddress,
                              jobAdvertId: item.jobAdvertId,
                            })
                          )
                        }
                      : undefined
                  }
                />
              </Col>
            ))}
          </Row>
        </Col>
        <Col flex="0 1 350px">
          <Title level={4}>{`Certificate of Compliance ` + getFlag()}</Title>
          <Upload
            style={{ marginLeft: 1 }}
            multiple={false}
            maxCount={1}
            accept={
              '.doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            }
            customRequest={async ({ file, onSuccess }) => {
              const reader = new FileReader()
              reader.onloadend = async () => {
                if (file && file instanceof File) {
                  await token.functionsClient?.certificateOfComplianceHttpFunction_Post(
                    new CertificateOfComplianceRequest({
                      fileData:
                        typeof reader.result === 'string'
                          ? reader.result
                          : undefined,
                      fileName: file.name,
                      emailAddress: emailAddress,
                    })
                  )
                }
              }

              if (file && file instanceof File) {
                reader.readAsDataURL(file)
              }
              setTimeout(() => {
                onSuccess?.('ok')
                setUploaded(1)
                setDelay(2000)
              }, 0)
            }}
          >
            <Button style={{ marginLeft: '10px' }}>Upload</Button>
          </Upload>
        </Col>
      </Row>
    </>
  )
}
