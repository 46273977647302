import { DatePicker, Space } from 'antd'
import Title from 'antd/lib/typography/Title'
import { useTimesheetsCsv } from 'pn.agencyportal.api.sdk/hooks'
import {
  Button,
  Hero,
} from 'pn.agencyportal.components/dist/src/components/Toolbox'
import { useState } from 'react'
const { RangePicker } = DatePicker
export function Timesheets() {
  const [dates, setDates] = useState<RangeValue>([undefined, undefined])
  var { data } = useTimesheetsCsv(dates[0], dates[1])

  type RangeValue = [Date | undefined, Date | undefined]

  return (
    <>
      <Hero title="Timesheets" />
      <Title level={4}>Please Select a date Range</Title>
      <Space direction="vertical">
        <RangePicker
          onCalendarChange={(props) => {
            const start = props?.[0]?.toDate()
            const end = props?.[1]?.toDate()
            start?.setUTCHours(0, 0, 0, 0)
            end?.setUTCHours(0, 0, 0, 0)

            setDates([start, end])
          }}
        />
        <Button
          disabled={dates[0] === undefined || dates[1] === undefined}
          onClick={() => {
            if (data?.data) {
              let url = window.URL.createObjectURL(data?.data)
              let a = document.createElement('a')
              a.href = url
              a.download = 'Timesheet.csv'
              a.click()
            }
          }}
        >
          Download
        </Button>
      </Space>
    </>
  )
}
