import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, InputRef, Space } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { FilterConfirmProps } from 'antd/lib/table/interface'
import { GridCandidate } from './Candidates'

const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => {
  confirm()
}
const handleReset = (
  clearFilters: () => void,
  confirm: (param?: FilterConfirmProps) => void
) => {
  clearFilters()
  confirm()
}

export const getColumnSearchProps = (
  ref: React.RefObject<InputRef>
): ColumnType<GridCandidate> => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={ref}
        placeholder={`Search`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(confirm)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value, record) =>
    record.fullName
      .toString()
      .toLowerCase()
      .includes((value as string).toLowerCase()),
})
