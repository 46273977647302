var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Card as AntCard, Col, PageHeader, Row, Tag } from 'antd';
import * as DOMPurify from 'dompurify';
import { dateDisplayText } from '../Helpers/date';
import { Button } from './Button';
export var JobCard = function (props) {
    var cleanJobDescription = DOMPurify.sanitize(props.jobDescription, {
        USE_PROFILES: { html: false },
    });
    var formattedDatePosted = props.datePosted
        ? dateDisplayText(props.datePosted)
        : undefined;
    var actions = [];
    if (props.viewDetailsOnClick) {
        actions.push(_jsx(Button, __assign({ onClick: props.viewDetailsOnClick }, { children: "View Details" })));
    }
    if (props.applyNowOnClick) {
        actions.push(_jsx(Button, __assign({ onClick: props.applyNowOnClick }, { children: "Apply Now" })));
    }
    if (props.cancelOnClick) {
        actions.push(_jsx(Button, __assign({ onClick: props.cancelOnClick }, { children: "Cancel" })));
    }
    return (_jsx(AntCard, __assign({ bodyStyle: { padding: '0px 24px' }, actions: actions, cover: _jsx(PageHeader, { title: props.jobTitle, subTitle: formattedDatePosted, extra: _jsx(Tag, __assign({ color: "".concat(props.jobState === 'Live' ? '#00d0bf' : 'blue') }, { children: props.jobState })) }) }, { children: _jsx(Row, __assign({ gutter: [16, 0] }, { children: _jsx(Col, { children: "".concat(cleanJobDescription.slice(0, 190), "...") }) })) })));
};
