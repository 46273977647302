import { InteractionType, PublicClientApplication } from '@azure/msal-browser'
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Layout, { Content, Header } from 'antd/lib/layout/layout'
import { ApiSdkProvider } from 'pn.agencyportal.api.sdk/provider'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { loginRequest } from './Auth/Config/authConfig'
import { MsalHeader } from './Auth/MsalHeader'
import { CustomNavigationClient } from './Auth/Utils/NavigationClient'
import { configuration, SiteConfiguration } from './configuration'

const queryClient = new QueryClient()

export default function App({
  pca,
}: {
  pca: PublicClientApplication
  configuration: SiteConfiguration
}) {
  const { instance } = useMsal()
  const account = instance.getActiveAccount()

  const [accessToken, setAccessToken] = useState<string | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      if (account) {
        const token = await instance.acquireTokenSilent(loginRequest)
        setAccessToken(token.accessToken)
      }
    }

    fetchData()
  }, [instance, account])

  return (
    <ClientSideNavigation pca={pca}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          {accessToken ? (
            <ApiSdkProvider
              accessToken={accessToken}
              clientUrl={configuration.apiUrl}
              functionsUrl={configuration.functionsUrl}
            >
              <Layout>
                <Header>
                  <MsalHeader />
                </Header>
                <Content>
                  <Outlet />
                </Content>
              </Layout>
            </ApiSdkProvider>
          ) : (
            <>Loading</>
          )}
        </MsalAuthenticationTemplate>
      </QueryClientProvider>
    </ClientSideNavigation>
  )
}

function ClientSideNavigation({
  pca,
  children,
}: {
  pca: PublicClientApplication
  children: React.ReactNode
}) {
  const navigate = useNavigate()
  const navigationClient = new CustomNavigationClient(navigate)
  pca.setNavigationClient(navigationClient)

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true)
  useEffect(() => {
    setFirstRender(false)
  }, [])

  if (firstRender) {
    return null
  }

  return <>{children}</>
}
