import { Space } from 'antd'
import { useJob } from 'pn.agencyportal.api.sdk/hooks'
import {
  Hero,
  JobAdvert,
} from 'pn.agencyportal.components/dist/src/components/Toolbox'
import { JobApplicants } from 'pn.agencyportal.components/dist/src/components/Toolbox/JobApplicants'
import { useNavigate, useParams } from 'react-router-dom'
import { JobParams } from './JobParams'

export function Job() {
  const { jobId } = useParams<JobParams>()
  const { data, isLoading } = useJob(Number.parseInt(jobId!))
  const navigate = useNavigate()

  return data ? (
    <>
      <Hero title={data.title} subTitle={data.pay}></Hero>
      <Space
        direction="horizontal"
        size={10}
        align={'start'}
        style={{ margin: '0 1em', padding: '1em', backgroundColor: 'white' }}
      >
        <JobAdvert
          datePosted={data.postedDate}
          jobDescription={data.description}
          jobRef={data.assignmentHeaderId.toString()}
          jobState={data.status.toString()}
          applyNowOnClick={
            data.status === 'Request'
              ? () => navigate(`/jobs/${data.assignmentHeaderId}/apply`)
              : undefined
          }
        />
        <JobApplicants
          applicants={data.applicants.map((x) => ({
            ...x,
            certificateOfComplianceCompleted: x.cocCocCompleted,
            applicantStatus: x.status,
            emailAddress: x.emailAddress,
          }))}
          jobState={data.status.toString()}
        />
      </Space>
    </>
  ) : isLoading ? (
    <>Loading</>
  ) : (
    <>Job Not Found</>
  )
}
