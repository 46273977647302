var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Menu, Row } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { SignInSignOutButton, } from '../Auth/SignInSignOutButton';
import { ProtocolLogo } from './ProtocolLogo';
export var Header = function (props) {
    var _a, _b;
    var backgroundColour = (_a = props.backgroundColour) !== null && _a !== void 0 ? _a : 'white';
    return (_jsxs(Row, __assign({ align: "middle", gutter: [10, 0], style: { background: backgroundColour, padding: '8px 60px' } }, { children: [_jsx(Col, __assign({ flex: "160px" }, { children: _jsx(ProtocolLogo, {}) })), _jsx(Col, __assign({ flex: "auto" }, { children: props.user ? (_jsx(Menu, { mode: "horizontal", items: props.items, style: { background: backgroundColour } })) : undefined })), _jsx(Col, __assign({ span: 5 }, { children: _jsx(Paragraph, __assign({ ellipsis: true, style: { margin: '0' } }, { children: (_b = props.user) === null || _b === void 0 ? void 0 : _b.name })) })), _jsx(Col, __assign({ span: "100px" }, { children: _jsx(SignInSignOutButton, __assign({}, props)) }))] })));
};
