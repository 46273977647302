export interface SiteConfiguration {
  apiUrl: string
  functionsUrl: string
  auth: {
    clientId: string
    authority: string
    domain: string
    scheme: string
    scopes: string[]
  }
  debug: boolean
}

var conf: SiteConfiguration = (window as any).config
//Configuration file can be found in public folder, this is done to enable the values to be replaced during deployment
export const configuration: SiteConfiguration = conf
