var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Space, Tag, Tooltip, Typography } from 'antd';
import Link from 'antd/lib/typography/Link';
import Paragraph from 'antd/lib/typography/Paragraph';
var Title = Typography.Title, Text = Typography.Text;
export var JobApplicants = function (props) { return (_jsxs(Space, __assign({ direction: "vertical", style: { margin: '0 2em' } }, { children: [_jsxs(Space, __assign({ direction: "horizontal" }, { children: [_jsx(Title, __assign({ level: 4 }, { children: "Status" })), _jsx(Tag, __assign({ color: "".concat(props.jobState.toString() === 'Live' ? '#00d0bf' : 'blue'), style: { marginBottom: '0.85em' } }, { children: props.jobState.toString() }))] })), _jsx(Title, __assign({ level: 2 }, { children: "Applicants" })), props.applicants.length === 0 ? 'No Applicants yet' : undefined, props.applicants.map(function (applicant) { return (_jsxs(_Fragment, { children: [_jsxs(Title, __assign({ level: 5 }, { children: [_jsx(Link, __assign({ href: "/candidates/".concat(encodeURIComponent(applicant.emailAddress)) }, { children: applicant.name })), _jsx(Tooltip, __assign({ title: 'Certificate Of Compliance Completed', placement: "left" }, { children: applicant.certificateOfComplianceCompleted ? '✅' : '❌' }))] })), _jsx(Paragraph, __assign({ style: { whiteSpace: 'nowrap' }, type: "secondary" }, { children: applicant.applicantStatus }))] })); })] }))); };
