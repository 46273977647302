export function dateDisplayText(time) {
    if (!time)
        return 'unknown';
    var difference = Math.abs(Date.now() - time.getTime());
    var minutes = Math.floor(difference / 1000 / 60);
    var result = '';
    if (minutes <= 60) {
        result = "".concat(minutes, " minutes ago");
    }
    if (minutes > 60 && minutes < 1440) {
        result = "".concat(Math.floor(minutes / 60), " hours ago");
    }
    if (minutes >= 1440) {
        result = "".concat(Math.ceil(minutes / 1440), " ").concat(minutes < 2400 ? 'day' : 'days', " ago");
    }
    return result;
}
