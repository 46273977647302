var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Space, Typography } from 'antd';
import DOMPurify from 'dompurify';
import { dateDisplayText } from '../Helpers/date';
import { Button } from './Button';
var Title = Typography.Title, Paragraph = Typography.Paragraph, Text = Typography.Text;
export var JobAdvert = function (props) {
    var cleanJobDescription = DOMPurify.sanitize(props.jobDescription, {
        USE_PROFILES: { html: true },
    });
    return (_jsxs(Space, __assign({ direction: "vertical", align: "center" }, { children: [props.jobTitle ? _jsx(Title, __assign({ level: 2 }, { children: props.jobTitle })) : null, props.jobPay ? _jsx(Title, __assign({ level: 3 }, { children: props.jobPay })) : null, _jsxs(Text, __assign({ type: "secondary" }, { children: ["Posted ".concat(dateDisplayText(props.datePosted)), props.jobRef && " | ref: ".concat(props.jobRef)] })), props.applyNowOnClick ? (_jsx(Button, __assign({ onClick: props.applyNowOnClick }, { children: "Apply Now" }))) : undefined, _jsx(Paragraph, __assign({ style: { width: '100%', alignContent: 'center' } }, { children: _jsx("div", { dangerouslySetInnerHTML: { __html: cleanJobDescription } }) }))] })));
};
