var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card as AntCard, Col, PageHeader, Row } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { dateDisplayText } from '../Helpers/date';
import { Button } from './Button';
export var CandidateJobCard = function (props) { return (_jsx(AntCard, __assign({ bodyStyle: { padding: '0px 24px' }, actions: [
        props.viewDetailsOnClick ? (_jsx(Button, __assign({ onClick: props.viewDetailsOnClick }, { children: "View Details" }))) : undefined,
        props.applyNowOnClick ? (_jsx(Button, __assign({ onClick: props.applyNowOnClick }, { children: "Apply Now" }))) : undefined,
        props.cancelOnClick ? (_jsx(Button, __assign({ onClick: props.cancelOnClick }, { children: "Cancel" }))) : undefined,
    ], cover: _jsx(PageHeader, { title: props.jobTitle, subTitle: props.datePosted ? dateDisplayText(props.datePosted) : undefined }) }, { children: _jsxs(Row, __assign({ wrap: false, gutter: [16, 0] }, { children: [_jsx(Col, __assign({ flex: "auto" }, { children: _jsx(Paragraph, __assign({ ellipsis: true }, { children: props.jobDescription })) })), _jsx(Col, __assign({ flex: "150px" }, { children: _jsxs("span", { children: [_jsx("h4", { children: "Candidate Status" }), props.jobState] }) }))] })) }))); };
