import { jsx as _jsx } from "react/jsx-runtime";
import { SignInButton } from './SignInButton';
import { SignOutButton } from './SignOutButton';
export var SignInSignOutButton = function (_a) {
    var handleLogin = _a.handleLogin, handleLogout = _a.handleLogout, inProgress = _a.inProgress, isAuthenticated = _a.isAuthenticated;
    if (isAuthenticated) {
        return _jsx(SignOutButton, { handleLogout: handleLogout });
    }
    else if (inProgress) {
        return _jsx(SignInButton, { handleLogin: handleLogin });
    }
    else {
        return null;
    }
};
