import { RightCircleOutlined } from '@ant-design/icons'
import { InputRef, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useCandidates } from 'pn.agencyportal.api.sdk/hooks'
import { Hero } from 'pn.agencyportal.components/dist/src/components/Toolbox/'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import { getColumnSearchProps } from './CandidatesSearch'

export interface GridCandidate {
  key: string
  fullName: string
  emailAddress: string
  children:GridCandidateJob[]
}

export interface GridCandidateJob{
  candidateStatus: string
  jobTitle: string
  jobId?: number
}

const isGridCandidate = (job: any): job is GridCandidate => {
  return (job as GridCandidate).emailAddress !== undefined;
};

const isGridCandidateJob = (job: any): job is GridCandidateJob => {
  return (job as GridCandidateJob).jobId !== undefined;
};

export function Candidates() {
  const candidates = useCandidates()
  const searchInput = useRef<InputRef>(null)

  const columns: ColumnsType<GridCandidate | GridCandidateJob | any> = [
    {
      title: 'Name',
      key: 'fullName',
      dataIndex: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      render: (text, record) => (
        <>
          {text}{' '}
          {isGridCandidate(record) &&
            <Link to={`/candidates/${encodeURIComponent(record.emailAddress)}`}>
              <RightCircleOutlined />
            </Link>
          }
        </>
      ),
      ...getColumnSearchProps(searchInput),
    },
    {
      title: 'Job',
      key: 'job',
      dataIndex: 'jobTitle',
      render: (text, record) => (
        <>
          {text}{' '}
          {isGridCandidateJob(record) && 
            <Link to={`/jobs/${record.jobId}`}>
              <RightCircleOutlined />
            </Link>
          }
        </>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'candidateStatus',
    },
  ]

  const gridCandidates: GridCandidate[] | undefined = candidates.data?.flatMap(
    (candidate, i) =>(
    {
        key: candidate.emailAddress + i,
        emailAddress: candidate.emailAddress,
        fullName: `${candidate.forename} ${candidate.surname}`,
        children: 
          candidate.jobs.map((
            (job, index) => ({
              key: candidate.emailAddress + job.title + index,
              candidateStatus: job.candidateStatus,
              jobTitle: job.title,
              jobId: job.assignmentHeaderId
            })
          ))
        ,
    }))

  return (
    <>
      <Hero title="Candidates" />
      <Table
        dataSource={gridCandidates}
        columns={columns}
        loading={candidates.isLoading}
      />
    </>
  )
}
