import {
    AuthenticationResult,
    EventPayload,
    EventType,
    PublicClientApplication
} from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { ConfigProvider } from 'antd'
import 'antd/dist/antd.min.css'
import en_GB from 'antd/lib/locale/en_GB'
import 'pn.agencyportal.components/dist/src/protocol-theme.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from './App'
import { msalConfig } from './Auth/Config/authConfig'
import { Candidate } from './Candidates/Candidate'
import { Candidates } from './Candidates/Candidates'
import { configuration } from './configuration'
import './index.css'
import { Job } from './Jobs/Job'
import { JobApply } from './Jobs/JobApply'
import Jobs from './Jobs/Jobs'
import { Timesheets } from './Timesheets/Timesheets'

const isAuthenticationResult = (
  value: EventPayload
): value is AuthenticationResult => value !== null && 'account' in value

export const msalInstance = new PublicClientApplication(
  msalConfig(configuration)
)

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents()

msalInstance.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS &&
    event &&
    isAuthenticationResult(event.payload)
  ) {
    const account = event.payload.account
    msalInstance.setActiveAccount(account)
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
  }
})

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <ConfigProvider locale={en_GB}>
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<App pca={msalInstance} configuration={configuration} />}
            >
              <Route index element={<Jobs />} />
              <Route path="jobs" element={<Jobs />} />
              <Route path="jobs/:jobId" element={<Job />} />
              <Route path="jobs/:jobId/apply" element={<JobApply />} />
              <Route path="candidates" element={<Candidates />} />
              <Route path="candidates/:emailAddress" element={<Candidate />} />
              <Route path="timesheets/" element={<Timesheets />} />
              <Route path="*" element={<>Page Not found</>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </MsalProvider>
    </React.StrictMode>
  </ConfigProvider>
)
