var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { Client } from '.';
import { FunctionsClient } from './functions';
export var AccessTokenContext = createContext({
    accessToken: null,
    apiClient: null,
    functionsClient: null,
});
export var useApiSdkContext = function () {
    return useContext(AccessTokenContext);
};
export var ApiSdkProvider = function (_a) {
    var accessToken = _a.accessToken, functionsUrl = _a.functionsUrl, clientUrl = _a.clientUrl, children = _a.children;
    var apiClient = new Client({
        getAuthorization: function () { return 'Bearer ' + accessToken; },
    }, clientUrl);
    var functionsClient = new FunctionsClient({
        getAuthorization: function () { return 'Bearer ' + accessToken; },
    }, functionsUrl);
    return (_jsx(AccessTokenContext.Provider, __assign({ value: {
            accessToken: accessToken,
            apiClient: apiClient,
            functionsClient: functionsClient,
        } }, { children: children })));
};
